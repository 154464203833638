<template>
  <div v-if="items">
    <v-card class="mb-4 primary darken-4" v-if="item" dark>
      <v-card-title>
        <v-btn icon @click="$router.push({ name: 'quiz' })">
          <v-icon>mdi-arrow-left</v-icon></v-btn
        >
        <v-spacer></v-spacer>
        <span v-if="$store.getters.getCurrLocale == 'en'">{{
          item.title
        }}</span>
        <span v-else>{{ item.title_hi ? item.title_hi : item.title }}</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-list class="primary darken-2" dark>
        <v-list-item v-if="items && items.length">
          <v-list-item-title>
            {{ $lang("Total") + " " + $lang("Questions") }}
          </v-list-item-title>
          <v-list-item-action> {{ items.length }} </v-list-item-action>
        </v-list-item>
        <v-card-actions v-else>
          <v-spacer></v-spacer>
          {{ $lang("No items") }}
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-list>
    </v-card>
    <div class="mb-2" v-for="(qstn, idx) in items" :key="idx">
      <v-card v-if="$store.getters.getCurrLocale == 'en'">
        <v-card-title class="subtitle-1">
          {{ qstn.title }}
        </v-card-title>
        <v-card-text>
          <v-icon class="mr-2 green--text">mdi-arrow-right</v-icon>
          <latex-html :html="getAnswer(qstn)" />
        </v-card-text>
      </v-card>
      <v-card v-else>
        <v-card-title class="subtitle-1">
          {{ qstn.title_hi ? qstn.title_hi : qstn.title }}
        </v-card-title>
        <v-card-text>
          <v-icon class="mr-2 green--text">mdi-arrow-right</v-icon>
          <latex-html :html="getAnswer(qstn)" />
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import { axios } from "@/plugins/axios";
import bus from "@/bus";
import LatexHtml from "@/components/LatexHtml";
export default {
  name: "questions",
  components: { LatexHtml },
  data() {
    return {
      item: null,
      items: [],
    };
  },
  computed: {},
  methods: {
    getAnswer(qstn) {
      let lcl = this.$store.getters.getCurrLocale;
      switch (qstn.correct_option) {
        case "1":
          return lcl == "en"
            ? qstn.option_a
            : qstn.option_a_hi
            ? qstn.option_a_hi
            : qstn.option_a;
          break;
        case "2":
          return lcl == "en"
            ? qstn.option_b
            : qstn.option_b_hi
            ? qstn.option_b_hi
            : qstn.option_b;
          break;
        case "3":
          return lcl == "en"
            ? qstn.option_c
            : qstn.option_c_hi
            ? qstn.option_c_hi
            : qstn.option_c;
          break;
        case "4":
          return lcl == "en"
            ? qstn.option_d
            : qstn.option_d_hi
            ? qstn.option_d_hi
            : qstn.option_d;
          break;

        default:
          return null;
          break;
      }
    },
    fetchItems() {
      bus.$emit("showWait");
      axios
        .get("prep/tags/public/" + this.$route.params.id + "/questions")
        .then((res) => {
          this.items = res.data.data;
        })

        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          bus.$emit("hideWait");
        });
    },
    fetchItem() {
      bus.$emit("showWait");

      axios
        .get("prep/tags/public/" + this.$route.params.id)
        .then((res) => {
          this.item = res.data;
        })
        .then(() => {
          this.fetchItems();
        })
        .catch((err) => {
          console.log(err);
          this.$router.replace({ name: "quiz" });
        })
        .finally(() => {
          bus.$emit("hideWait");
        });
    },
  },
  created() {
    this.fetchItem();
    // this.fetchItems();
  },
};
</script>

